import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TeamMenagment from "../assets/img/TeamMenagment.svg";

import onlineworkcalendar from "../assets/img/online-work-calendar.svg";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";
import manageworkload from "../assets/img/manage-workload.svg";

import filtericon from "../assets/img/filter-icon.svg";
import securitytimeicon from "../assets/img/security-time-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";
import printericon from "../assets/img/printer-icon.svg";
import folderopenicon from "../assets/img/folder-open-icon.svg";
import cardediticon from "../assets/img/card-edit-icon.svg";

import firms from "../assets/img/firms.svg";
import firms2 from "../assets/img/firms2.svg";

function TeamManagement() {
     return (
          <div>
               <div className="hero-section common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={6}>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-20">Team Collaboration and Workload Visibility in One Shared Space</h2>
                                        <p className="mb-60">
                                             Get a pulse on your team's workload - whether you have a team of 5 or 500. Trackmydoc makes it
                                             easy to ensure that everyone stays focused and aligned with the firm’s goals. Easily
                                             collaborate, communicate and rebalance work when needed to deliver quality services together.
                                             Assign tasks, share docs, send emails and mention team members, all within one shared space.
                                        </p>
                                        <div className="d-flex">
                                             <a className="btn btn-primary">Request Demo</a>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={1}></Col>
                              <Col lg={5}>
                                   <div className="hero-picture">
                                        <img src={TeamMenagment} />
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="12">
                                   <div className="_text text-center mb-60">
                                        <h2 className="mb-20">Supercharge your teamwork with automations</h2>
                                        <p className="mb-0 mx-1020">
                                             Focus on delivering flawless client service and increase billable hours by automating
                                             repetitive, low-value tasks. Create dependencies for different workflows – and improve
                                             efficiency in your work and within your team.
                                        </p>
                                   </div>
                              </Col>
                              <Col lg="12">
                                   <Row>
                                        <Col xs={12} md={6} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>2.5M+</h4>
                                                  <p className="mb-0">Total automation sent in 2023</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>1.5M+</h4>
                                                  <p className="mb-0">Email and secure chat automation sent</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>40+</h4>
                                                  <p className="mb-0 mx-230">Hours saved per month per team member</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>200,000+</h4>
                                                  <p className="mb-0 mx-230">Engagement letters generated automatically</p>
                                             </div>
                                        </Col>
                                   </Row>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="4" xl={5}>
                                   <div className="_text mb-60">
                                        <h2 className="mb-20">Firms boost teamwork performance with Trackmydoc</h2>
                                        <p className="mb-0">
                                             Find out how our clients achieved enhanced team collaboration and improved overall performance
                                             by incorporating Trackmydoc into their practices.
                                        </p>
                                   </div>
                              </Col>
                              <Col lg={8} xl={7}>
                                   <Row>
                                        <Col md={6}>
                                             <div className="firm-box">
                                                  <img src={firms} />
                                                  <h5 className="mt-5 secondary-color mb-12">
                                                       Athene Group experience: Managing a team of 100+ staff across 10 offices with
                                                       Trackmydoc
                                                  </h5>
                                                  <p className="mb-0">
                                                       Before Trackmydoc we used one system for handling sensitive documents, one system for
                                                       sharing documents, one system for task management… Packing that into a single system
                                                       instead of 10 different ones is great.
                                                  </p>
                                             </div>
                                        </Col>
                                        <Col md={6}>
                                             <div className="firm-box mtop-100">
                                                  <img src={firms2} />
                                                  <h5 className="mt-5 secondary-color mb-12">
                                                       Polaris Tax & Accounting: post-acquisition expansion from 50 customers to 1,500+
                                                  </h5>
                                                  <p className="mb-0">
                                                       We don't have to worry about having external drives for the data. We don't have to
                                                       worry about having a digital signature software provider. It just makes it easier to
                                                       have everything in one single platform.
                                                  </p>
                                             </div>
                                        </Col>
                                   </Row>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="5">
                                   <div className="_text res-mb-40">
                                        <h2 className="mb-12">Get all your deadlines under control</h2>
                                        <p className="mb-12">
                                             Bring transparency to your workflow. Navigate projects and processes with ease. Plan and manage
                                             the strategic side of work down to everyday tasks. Each client, job and task will be attended
                                             to.
                                        </p>
                                   </div>
                              </Col>
                              <Col lg="1"></Col>
                              <Col lg="6">
                                   <div className="bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <h4 className="mb-12 secondary-color">Online work Calendar</h4>
                                             <p className="mx-588">
                                                  Never miss a deadline with the customizable Trackmydoc Calendar: visualize your work in a
                                                  handy timeline format. Adjust it to your firm's workflow and define how work gets done.
                                                  Easily prioritize and reassign your team's workload for the next day, week or month.
                                             </p>
                                             <a className="btn btn-primary">Explore Trackmydoc Calendar</a>
                                             <img className="mt-5" src={onlineworkcalendar} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="bg-color radius-24 res-mb-40">
                                        <div className="combine-picture text-center my-5 p-5">
                                             <img src={manageworkload} />
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="1"></Col>
                              <Col lg="5">
                                   <div className="_text">
                                        <h2 className="mb-20">Manage workloads and increase team efficiency</h2>
                                        <p className="mb-0">
                                             Use Capacity planning to allocate work to your team, track their workloads, and prevent
                                             overbooking.
                                        </p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding">
                    <div className="website-container">
                         <div className="text-center mb-80">
                              <h2 className="text-center mb-20">Team collaboration features</h2>
                              <p className="mx-1020 mb-0">Manage your team efficiently using Trackmydoc collaboration features.</p>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Insights</h5>
                                        </div>
                                        <p className="min-72">Use your personalized dashboard to review team performance</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={securitytimeicon} />
                                             </div>
                                             <h5 className="mb-0">Wiki pages</h5>
                                        </div>
                                        <p className="min-72">Create SOPs and cheat sheets to facilitate team onboarding</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={printericon} />
                                             </div>
                                             <h5 className="mb-0">@Mentions</h5>
                                        </div>
                                        <p className="min-72">
                                             Mention colleagues inside tasks and chat threads to ask questions on the spot
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={folderopenicon} />
                                             </div>
                                             <h5 className="mb-0">Notes</h5>
                                        </div>
                                        <p className="min-72">Use notes to create internal memos about specific accounts</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={filtericon} />
                                             </div>
                                             <h5 className="mb-0">Notifications</h5>
                                        </div>
                                        <p className="min-72">Receive notifications and respond to them directly from your Inbox+</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={clipboardtexticon} />
                                             </div>
                                             <h5 className="mb-0">Audit trail</h5>
                                        </div>
                                        <p className="min-72">Have access to documentation for who worked on what documents and when</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Emails</h5>
                                        </div>
                                        <p className="min-72">Sync your email providers to use your email directly within Trackmydoc</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={cardediticon} />
                                             </div>
                                             <h5 className="mb-0">Tasks</h5>
                                        </div>
                                        <p className="min-72">Create a task or follow up from any email</p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default TeamManagement;
