import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import logo from "./logo.svg";
import Footer from "./components/Footer.js";
import Home from "./components/Home.js";
import CRMApp from "./components/CRMApp.js";
import TaxOrganizers from "./components/TaxOrganizers.js";
import TimeBilling from "./components/TimeBilling.js";
import WebsiteBranding from "./components/WebsiteBranding.js";
import TeamManagement from "./components/TeamManagement.js";
import DocumentManagement from "./components/DocumentManagement.js";
import SecureMessages from "./components/SecureMessages.js";
import ClientPortal from "./components/ClientPortal.js";
import Inbox from "./components/Inbox.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop.js";

function App() {
     return (
          <Router>
               <div className="App">
                    <Header className="App-header"></Header>
                    <div className="main-content">
                         <ScrollToTop />
                         <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/crm" element={<CRMApp />} />
                              <Route path="/tax-organizers" element={<TaxOrganizers />} />
                              <Route path="/time-billing" element={<TimeBilling />} />
                              <Route path="/website-branding" element={<WebsiteBranding />} />
                              <Route path="/team-management" element={<TeamManagement />} />
                              <Route path="/document-management" element={<DocumentManagement />} />
                              <Route path="/secure-messages" element={<SecureMessages />} />
                              <Route path="/client-portal" element={<ClientPortal />} />
                              <Route path="/inbox" element={<Inbox />} />
                         </Routes>
                    </div>
                    <Footer></Footer>
               </div>
          </Router>
     );
}

export default App;
