import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import arrowright from "../assets/img/arrow-right-long.svg";

import herodevice from "../assets/img/hero-device.svg";

import laptop1 from "../assets/img/laptop-1.svg";
import device1 from "../assets/img/device-1.svg";

import map from "../assets/img/map.png";
import clientmobileapp from "../assets/img/client-mobile-app.svg";

import appstore1 from "../assets/img/app-store-1.png";
import googleplay1 from "../assets/img/google-play-1.png";

import anualreportlogo from "../assets/img/anual-report.svg";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";
import midsizedlargefirmslogo from "../assets/img/mid-sized-large-firms.svg";
import enterpriselogo from "../assets/img/enterprise.svg";
import accountantslogo from "../assets/img/accountants.svg";
import bookkeeperslogo from "../assets/img/bookkeepers.svg";
import taxpreparerslogo from "../assets/img/tax-preparers.svg";

import capterralogo from "../assets/img/trusted/capterra.svg";
import happiestuserlogo from "../assets/img/trusted/happiest-user.svg";
import qualitychoicelogo from "../assets/img/trusted/quality-choice.svg";
import trustedvendorlogo from "../assets/img/trusted/trusted-vendor.svg";
import getapp1logo from "../assets/img/trusted/getapp-1.svg";
import smallbusinessleaderlogo from "../assets/img/trusted/small-business-leader.svg";
import highperformerlogo from "../assets/img/trusted/highperformer.svg";
import leaderleaderlogo from "../assets/img/trusted/leader-leader.svg";
import accountingsmallbusinessleaderlogo from "../assets/img/trusted/accounting-small-business-leader.svg";
import momentumleaderleaderlogo from "../assets/img/trusted/momentumleader-leader.svg";
import usersloveuslogo from "../assets/img/trusted/users-love-us.svg";

import howtrackmydochelpedlogo from "../assets/img/how-trackmydoc-helped.svg";
import acurrantlogo from "../assets/img/acurrant.svg";

import irslogo from "../assets/img/irs.svg";
import danskelogo from "../assets/img/danske.svg";
import talouslogo from "../assets/img/talous.svg";
import ecflogo from "../assets/img/ecf.svg";
import ctplogo from "../assets/img/ctp.svg";
import naealogo from "../assets/img/naea.svg";
import ifeclogo from "../assets/img/ifec.svg";
import cjeclogo from "../assets/img/cjec.svg";
import ordrdeslogo from "../assets/img/ordrdes.svg";
import ceredalogo from "../assets/img/cereda.svg";

import traningincludedlogo from "../assets/img/traning-included.svg";
import roadmaplogo from "../assets/img/road-map.svg";
import recentlyupdate from "../assets/img/recently-update.png";

import heroiconlefttop from "../assets/img/hero-icon-left-top.svg";
import heroiconrighttop from "../assets/img/hero-icon-right-top.svg";
import heroiconleftmiddle from "../assets/img/hero-icon-left-middle.svg";
import heroiconrightmiddle from "../assets/img/hero-icon-right-middle.svg";

function Home() {
     return (
          <div>
               {/*hero section*/}
               <div className="hero-section pattern-line common-padding pb-0">
                    <Container>
                         <div className="__text text-center res-mb-40 mb-110">
                              {/* <img className="hero-icon heroiconlefttop" src={heroiconlefttop} />
                        <img className="hero-icon heroiconrighttop" src={heroiconrighttop} /> */}
                              <h1 className="mb-20">
                                   The most powerful platform to manage <br />
                                   <span className="primary-color">teams, clients, projects</span>
                              </h1>
                              <p className="f-18 mb-20">Practice management software for tax, bookkeeping and accounting firms</p>
                              <div className="d-flex justify-content-center">
                                   <a className="btn btn-primary me-3">Request Demo</a>
                                   <a className="btn btn-outline-primary">Start free Trial</a>
                              </div>
                         </div>

                         {/* <div className="hero-picture">
                        <img className="hero-icon heroiconleftmiddle" src={heroiconleftmiddle} />
                        <img className="hero-icon heroiconrightmiddle" src={heroiconrightmiddle} />
                        <img className="herodevice-img" src={herodevice} />
                    </div> */}
                    </Container>
               </div>

               {/*Anual Report Section*/}
               <div className="website-container">
                    <div className="anual-report common-margin">
                         <Row xs={1} md={1} lg={2} className="align-items-center">
                              <Col>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-20">
                                             Annual report <span className="primary-color">2024</span>
                                        </h2>
                                        <p className="mb-20">Get the summary of our achievements and the founders' letter</p>
                                        <a className="btn btn-primary">Get the Report</a>
                                   </div>
                              </Col>
                              <Col>
                                   <img src={anualreportlogo} />
                              </Col>
                         </Row>
                    </div>
               </div>

               {/*Industry leader trusted Section*/}
               <div className="leader-trusted common-margin">
                    <div className="website-container">
                         <div className="__text text-center mb-80">
                              <h2 className="mb-20">
                                   Industry leader trusted by <span className="primary-color">10,000+</span> firms
                              </h2>
                              <p className="mb-0">
                                   Trackmydoc has 6,200+ reviews on Capterra,G2 and GetApp with an average score of 4.7/5.
                              </p>
                         </div>
                         <div className="trusted-list">
                              <Row className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5 justify-content-center row">
                                   <Col>
                                        <div className="box">
                                             <div className="picture">
                                                  <img src={capterralogo} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <div className="box">
                                             <div className="picture">
                                                  <img src={happiestuserlogo} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <div className="box">
                                             <div className="picture">
                                                  <img src={qualitychoicelogo} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <div className="box">
                                             <div className="picture">
                                                  <img src={trustedvendorlogo} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <div className="box">
                                             <div className="picture">
                                                  <img src={getapp1logo} />
                                             </div>
                                        </div>
                                   </Col>
                              </Row>
                              <Row className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xl-6 justify-content-center row">
                                   <Col>
                                        <div className="box">
                                             <div className="picture opacity-low">
                                                  <img src={smallbusinessleaderlogo} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <div className="box">
                                             <div className="picture opacity-low">
                                                  <img src={highperformerlogo} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <div className="box">
                                             <div className="picture opacity-low">
                                                  <img src={leaderleaderlogo} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <div className="box">
                                             <div className="picture opacity-low">
                                                  <img src={accountingsmallbusinessleaderlogo} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <div className="box">
                                             <div className="picture opacity-low">
                                                  <img src={momentumleaderleaderlogo} />
                                             </div>
                                        </div>
                                   </Col>
                                   <Col>
                                        <div className="box">
                                             <div className="picture opacity-low">
                                                  <img src={usersloveuslogo} />
                                             </div>
                                        </div>
                                   </Col>
                              </Row>
                         </div>
                    </div>
               </div>

               <div className="customization common-padding common-margin">
                    <div className="website-container">
                         <h2 className="text-center mb-80 pt-16">Customizable for all firm sizes and types</h2>
                         <Row>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Sole practitioner</h5>
                                        </div>
                                        <p className="box-min-height-120">
                                             Store all data related to every client in a centralized hub and eliminate the need for
                                             back-and-forth emails. Automate all of your tasks and maximize efficiency of your client
                                             interactions. All your clients, tasks, docs, invoices, in one place.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={midsizedlargefirmslogo} />
                                             </div>
                                             <h5 className="mb-0">Mid-sized & large firms</h5>
                                        </div>
                                        <p className="box-min-height-120">
                                             Enable your staff to be more productive and scale efficiently. Build repeatable, reliable
                                             workflows across multiple service offerings and teams. Outstanding client experience to build
                                             your brand and increase retention.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={enterpriselogo} />
                                             </div>
                                             <h5 className="mb-0">Enterprise</h5>
                                        </div>
                                        <p className="box-min-height-120">
                                             Visibility and control over all processes. Implement foolproof SOPs to scale and enable your
                                             teams to succeed. Outstanding client experience to build your brand and increase retention.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={accountantslogo} />
                                             </div>
                                             <h5 className="mb-0">Accountants</h5>
                                        </div>
                                        <p className="box-min-height-120">
                                             Automate document request processes, leverage pre-made templates and set up recurring work.
                                             Utilize time and billing, WIP reports and more.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={bookkeeperslogo} />
                                             </div>
                                             <h5 className="mb-0">Bookkeepers</h5>
                                        </div>
                                        <p className="box-min-height-120">
                                             Use ready templates or create your unique customized workflows. Ensure all routine tasks are
                                             done on schedule, so you have more time to help your clients.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={taxpreparerslogo} />
                                             </div>
                                             <h5 className="mb-0">Tax preparers</h5>
                                        </div>
                                        <p className="box-min-height-120">
                                             From intake to e-signature and everything in between. Send engagement letters, automate client
                                             communication, download IRS transcripts, get paid and much more.
                                        </p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin how-trackmydoc">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={6} xl={7}>
                                   <div className="__text res-mb-40">
                                        <h2>How Trackmydoc Helped a 40-Person Accounting Firm Become More Efficient and Scalable</h2>

                                        <p className="mb-2">
                                             <b className="secondary-color">Firm:</b> Accurint LLC
                                        </p>
                                        <p className="mb-30">
                                             <b className="secondary-color">Services:</b> Accounting, bookkeeping, tax preparation
                                        </p>

                                        <a className="btn btn-primary me-3">Get the Free E-Book</a>
                                        <div className="mt-50">
                                             <div className="mx-700">
                                                  <div className="counting-box text-center w-100">
                                                       <Row className="justify-content-between">
                                                            <Col>
                                                                 <h4 className="primary-color fw-700">9,000+</h4>
                                                                 <p className="mb-0">Clients</p>
                                                            </Col>
                                                            <Col>
                                                                 <h4 className="primary-color fw-700">40+</h4>
                                                                 <p className="mb-0">Team Members</p>
                                                            </Col>
                                                            <Col>
                                                                 <h4 className="primary-color fw-700">6</h4>
                                                                 <p className="mb-0">Offices</p>
                                                            </Col>
                                                       </Row>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={6} xl={5} className="text-center">
                                   <a className="_picture">
                                        <div className="tooltip-popup">
                                             <div>
                                                  <img src={acurrantlogo} alt="acurrantlogo" />
                                             </div>
                                             <div className="_right text-start">
                                                  <h5 className="mb-0 secondary-color">Barb Vanags</h5>
                                                  <p className="mb-0  secondary-color">Managing Partner</p>
                                             </div>
                                        </div>
                                        <img src={howtrackmydochelpedlogo} alt="How Trackmydoc helped" />
                                   </a>
                              </Col>
                         </Row>
                    </div>
                    <div className="website-container">
                         <div className="integrations-section">
                              <h2 className="mb-60 text-center">
                                   Integrations and partnerships with
                                   <br />
                                   leading tax & accounting organizations
                              </h2>
                              <div>
                                   <Row className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xl-5 justify-content-center">
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={irslogo} alt="irs" />
                                                  </a>
                                             </div>
                                        </Col>
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={danskelogo} alt="danske" />
                                                  </a>
                                             </div>
                                        </Col>
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={talouslogo} alt="talous" />
                                                  </a>
                                             </div>
                                        </Col>
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={ecflogo} alt="ecf" />
                                                  </a>
                                             </div>
                                        </Col>
                                   </Row>
                                   <Row className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 row-cols-xl-6 justify-content-center">
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={ctplogo} alt="ctp" />
                                                  </a>
                                             </div>
                                        </Col>
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={naealogo} alt="naea" />
                                                  </a>
                                             </div>
                                        </Col>
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={ifeclogo} alt="ifec" />
                                                  </a>
                                             </div>
                                        </Col>
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={cjeclogo} alt="cjec" />
                                                  </a>
                                             </div>
                                        </Col>
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={ordrdeslogo} alt="ordrdes" />
                                                  </a>
                                             </div>
                                        </Col>
                                        <Col>
                                             <div className="shadow-box shadow-0">
                                                  <a className="_picture d-block text-center">
                                                       <img src={ceredalogo} alt="cereda" />
                                                  </a>
                                             </div>
                                        </Col>
                                   </Row>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="common-margin all-in-one-solution">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="me-xl-5">
                                        <div className="_text mb-50">
                                             <span className="mb-12 primary-color fw-500 d-block">All-in-one solution</span>
                                             <h2 className="mb-30">
                                                  Fuel your firm's growth with best in-class practice management software
                                             </h2>
                                             <p>
                                                  Trackmydoc provides you with an intuitive modern solution to work efficiently in a digital
                                                  world. Bring your firm's critical operations, clients and team in one shared workspace.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>
                                                            Client management to build stronger relationships
                                                       </Accordion.Header>
                                                       <Accordion.Body>
                                                            Exchange documents, collect e-signatures, issue invoices and communicate with
                                                            your clients via an intuitive, custom-branded client portal and mobile app.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Branded client portal and mobile app</Accordion.Header>
                                                       <Accordion.Body>
                                                            Exchange documents, collect e-signatures, issue invoices and communicate with
                                                            your clients via an intuitive, custom-branded client portal and mobile app.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Proposals and engagement letters</Accordion.Header>
                                                       <Accordion.Body>
                                                            Exchange documents, collect e-signatures, issue invoices and communicate with
                                                            your clients via an intuitive, custom-branded client portal and mobile app.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>Customizable digital intake forms (organizers)</Accordion.Header>
                                                       <Accordion.Body>
                                                            Exchange documents, collect e-signatures, issue invoices and communicate with
                                                            your clients via an intuitive, custom-branded client portal and mobile app.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="4">
                                                       <Accordion.Header>Time & billing integrated in one workflow</Accordion.Header>
                                                       <Accordion.Body>
                                                            Exchange documents, collect e-signatures, issue invoices and communicate with
                                                            your clients via an intuitive, custom-branded client portal and mobile app.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-850 bg-color radius-24">
                                        <div className="combine-picture text-center p-5 my-5">
                                             <img src={laptop1} />
                                             <img className="second-img" src={device1} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="12">
                                   <div className="_text text-center mb-60">
                                        <span className="mb-12 primary-color fw-500 d-block">Central hub for running your firm</span>
                                        <h2 className="mb-20">Manage your team and clients in one system</h2>
                                        <p className="mb-0 mx-1020">
                                             Optimize your practice by using a single platform for back-office operations (workflow, CRM,
                                             reporting) and client-facing tools (data, signatures, invoicing, messages). Have every piece of
                                             client document, email, invoice, and task all easily available in a shared place for your team.
                                        </p>
                                   </div>
                              </Col>
                              <Col lg="12">
                                   <Row>
                                        <Col xs={12} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>3M+</h4>
                                                  <p className="mb-0">Automations created in 2023</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>1M+</h4>
                                                  <p className="mb-0">Email and secure chat sent automatically</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>200K+</h4>
                                                  <p className="mb-0 mx-230">Engagement letters generated automatically</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} lg={6} xl={3}>
                                             <div className="blue-box text-center">
                                                  <h4>40M+</h4>
                                                  <p className="mb-0 mx-230">Hours saved per month per team member</p>
                                             </div>
                                        </Col>
                                   </Row>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="globe common-margin">
                    <div className="website-container">
                         <Row>
                              <Col lg="5">
                                   <div className="_text res-mb-40">
                                        <span className="mb-12 primary-color fw-500 d-block">Global accounting platform</span>
                                        <h2 className="mb-20">Multilingual platform used across the globe</h2>
                                        <p className="f-18 mb-20">
                                             Use Trackmydoc in your preferred language (12 and counting) and deliver a top-notch experience
                                             to customers in their language of choice.
                                        </p>
                                        <p className="f-18 mb-20">
                                             Trackmydoc is used by firms in 25+ countries with clients logging in from 94+ countries.
                                        </p>
                                        <p className="f-18">Implementation and support provided in multiple languages.</p>
                                   </div>
                              </Col>
                              <Col lg="1"></Col>
                              <Col lg="6">
                                   <img alt="map" src={map} />
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="5">
                                   <div className="_text res-mb-40">
                                        <span className="mb-12 primary-color fw-500 d-block">Mobility, security and transparency</span>
                                        <h2 className="mb-20">Access Trackmydoc from anywhere</h2>
                                        <p className="mb-60">
                                             We value your convenience and time as much as you do. Use Trackmydoc while you commute and have
                                             uninterrupted access to all workflows from any device, anywhere, anytime.
                                        </p>
                                        <div className="d-flex">
                                             <a className="me-3">
                                                  <img alt="google play" src={googleplay1} />
                                             </a>
                                             <a>
                                                  <img alt="app store" src={appstore1} />
                                             </a>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="1"></Col>
                              <Col lg="6">
                                   <div className="bg-color radius-24">
                                        <div className="p-4 p-xl-5 pb-0 pb-lg-0">
                                             <h3 className="mb-12 secondary-color">Client mobile app</h3>
                                             <p className="mb-60">
                                                  With the native iOS and Android apps, enable your clients to interact with your firm from
                                                  any location. Using their phones, clients can scan, upload, e-sign and approve documents,
                                                  complete organizers, securely pay invoices and send you messages from the client portal or
                                                  the mobile app.
                                             </p>
                                             <div className="text-center">
                                                  <img alt="client mobile app" src={clientmobileapp} />
                                             </div>
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <img src={traningincludedlogo} alt="training included" />
                              </Col>
                              <Col lg="6">
                                   <div className="_text res-mb-40 ms-xl-5">
                                        <h2 className="mb-20">Implementation & team training included</h2>
                                        <p className="mb-50">
                                             Facilitate learning opportunities for everyone by opening doors. Enable file sharing both
                                             within your organization and with remote trainees.
                                        </p>
                                        <ul className="list-50 list-ui checked-icon">
                                             <li>The knowledge base</li>
                                             <li>Trackmydoc community</li>
                                             <li>Trackmydoc Academy</li>
                                             <li>Screen-sharing</li>
                                             <li>Webinars</li>
                                        </ul>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding bg-color">
                    <div className="website-container">
                         <div className="text-center feature-box p-0">
                              <h2 className="mb-20">Roadmap</h2>
                              <p className="mb-20">See what we have planned and what new functionality to expect</p>
                              <div className="flex items-center">
                                   <a className="link">Read more</a>
                                   <img className="ms-2" src={arrowright} />
                              </div>
                              <div className="step mt-60 mb-60">
                                   <ul>
                                        <li>
                                             <span className="mb-20">1</span>
                                             <b>Start New Chat</b>
                                        </li>
                                        <li>
                                             <span className="mb-20">2</span>
                                             <b>Old Chat</b>
                                        </li>
                                        <li>
                                             <span className="mb-20">3</span>
                                             <b>Select your file</b>
                                        </li>
                                        <li>
                                             <span className="mb-20">4</span>
                                             <b>Continue Chat</b>
                                        </li>
                                   </ul>
                              </div>
                              <img src={roadmaplogo} alt="road map" />
                         </div>
                    </div>
               </div>

               <div className="website-container">
                    <div className="accordion common-margin">
                         <span className="mb-20 primary-color fw-500 d-block text-center">Your concerns, answered</span>
                         <h2 className="text-center mb-60">Frequently asked questions</h2>
                         <Accordion defaultActiveKey="1">
                              <Accordion.Item eventKey="0">
                                   <Accordion.Header>What is accounting practice management software?</Accordion.Header>
                                   <Accordion.Body>
                                        No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once you
                                        sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                        onboarding session with screen sharing.
                                   </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1">
                                   <Accordion.Header>Are there onboarding fees?</Accordion.Header>
                                   <Accordion.Body>
                                        No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once you
                                        sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                        onboarding session with screen sharing.
                                   </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="2">
                                   <Accordion.Header>
                                        What is the estimated time frame for setting up Trackmydoc from start to finish?
                                   </Accordion.Header>
                                   <Accordion.Body>
                                        No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once you
                                        sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                        onboarding session with screen sharing.
                                   </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="3">
                                   <Accordion.Header>
                                        Is Trackmydoc for managing the office and facilitating internal and external communication but not
                                        preparing tax returns?
                                   </Accordion.Header>
                                   <Accordion.Body>
                                        No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once you
                                        sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                        onboarding session with screen sharing.
                                   </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="4">
                                   <Accordion.Header>What is included into your trial?</Accordion.Header>
                                   <Accordion.Body>
                                        No, training is 100 percent free. We provide unlimited support on the phone or by chat. Once you
                                        sign up with the trial, you'll receive a message from our team to schedule your one-on-one
                                        onboarding session with screen sharing.
                                   </Accordion.Body>
                              </Accordion.Item>
                         </Accordion>
                    </div>
               </div>
          </div>
     );
}
export default Home;
