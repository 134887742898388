import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import websitebranding from "../assets/img/website-branding.svg";

import putyourbrandfrontandcenter from "../assets/img/putyourbrand-frontandcenter.svg";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";
import filtericon from "../assets/img/filter-icon.svg";
import securitytimeicon from "../assets/img/security-time-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";
import printericon from "../assets/img/printer-icon.svg";
import folderopenicon from "../assets/img/folder-open-icon.svg";
import cardediticon from "../assets/img/card-edit-icon.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from "react";
import Slider from "react-slick";

import quote from "../assets/img/quote.svg";
import staremptybig from "../assets/img/star-empty-big.svg";
import starfillbig from "../assets/img/star-fill-big.svg";
import testimonials1 from "../assets/img/testimonials-1.svg";

function WebsiteBranding() {
     const settings = {
          dots: true,
          arrows: false,
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "0",
          slidesToShow: 3,
          speed: 500,
          responsive: [
               {
                    breakpoint: 1025,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
               {
                    breakpoint: 991,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
               {
                    breakpoint: 767,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
               {
                    breakpoint: 480,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
          ],
     };

     return (
          <div>
               <div className="hero-section common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={5}>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-20">Custom Website & Branding</h2>
                                        <p className="mb-20">
                                             While Trackmydoc is the operating system that powers your practice, the relationship is between
                                             you and your clients. You can fully custom-brand your client app and your portal, including the
                                             URL for your own domain.
                                        </p>
                                        <p className="mb-60">
                                             And if you don't have a website yet, we've got you covered with a user-friendly website builder
                                             with 200+ templates adapted to the accounting, tax and bookkeeping business needs (at no extra
                                             charge).
                                        </p>
                                        <div className="d-flex">
                                             <a className="btn btn-primary">Request Demo</a>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={1}></Col>
                              <Col lg={6}>
                                   <div className="hero-picture">
                                        <img src={websitebranding} />
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding-top">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="min-height-650 bg-color radius-24 res-mb-40">
                                        <div className="combine-picture text-center p-5">
                                             <img src={putyourbrandfrontandcenter} />
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="col-xl-pdleft30">
                                        <div className="_text">
                                             <h2 className="mb-20">Put your brand front and center</h2>
                                             <p className="mb-60">
                                                  Your firm and clients will always see your personalized branding — not a generic portal
                                                  site.
                                             </p>
                                             <ul className="list-ui checked-icon">
                                                  <li>Set your own website URL</li>
                                                  <li>Customize your welcome messaging</li>
                                                  <li>Brand your client portal and mobile app</li>
                                                  <li>Customize your firm's login page (photo, logo, description)</li>
                                             </ul>
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <div className="row">
                              <div className="col-md-12">
                                   <div className="slider-container mb-60">
                                        <Slider {...settings}>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </Slider>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="customization common-padding">
                    <div className="website-container">
                         <div className="text-center mb-80">
                              <h2 className="text-center mb-20">Create tax organizers customized to your clients</h2>
                              <p className="mx-1020 mb-0">
                                   Make use of various organizer features to gather and sort client data. Send 1 or 10,000 at just the right
                                   time, either manually or automatically, and receive timely notifications when they're completed.
                              </p>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Website builder</h5>
                                        </div>
                                        <p className="min-72">User-friendly editor, no programming skills required</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={securitytimeicon} />
                                             </div>
                                             <h5 className="mb-0">Branded communications</h5>
                                        </div>
                                        <p className="min-72">Clients will see your brand in all touchpoints & communications</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={printericon} />
                                             </div>
                                             <h5 className="mb-0">Website hosting</h5>
                                        </div>
                                        <p className="min-72">
                                             Your subscription includes Trackmydoc website hosting, no third parties involved
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={folderopenicon} />
                                             </div>
                                             <h5 className="mb-0">Secure SSL certificate</h5>
                                        </div>
                                        <p className="min-72">
                                             All web traffic is SSL/TLS-encrypted, and clients can verify your ownership
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={filtericon} />
                                             </div>
                                             <h5 className="mb-0">Import</h5>
                                        </div>
                                        <p className="min-72">Simply import your existing site to Trackmydoc at no cost</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={clipboardtexticon} />
                                             </div>
                                             <h5 className="mb-0">Custom URL</h5>
                                        </div>
                                        <p className="min-72">Add your own domain to reflect your brand</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Templates</h5>
                                        </div>
                                        <p className="min-72">200+ customizable templates to help you promote your brand online</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={cardediticon} />
                                             </div>
                                             <h5 className="mb-0">Responsive</h5>
                                        </div>
                                        <p className="min-72">Your site will look great on any device, including desktop and smartphones</p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default WebsiteBranding;
