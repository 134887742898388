import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import appstore1 from "../assets/img/app-store-1.png";
import googleplay1 from "../assets/img/google-play-1.png";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";

import Invoice from "../assets/img/Invoice.svg";
import payonthego from "../assets/img/payonthego.svg";

import filtericon from "../assets/img/filter-icon.svg";
import securitytimeicon from "../assets/img/security-time-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";

import quote from "../assets/img/quote.svg";
import starempty from "../assets/img/star-empty.svg";
import starfill from "../assets/img/star-fill.svg";
import review1 from "../assets/img/review-1.svg";
import review2 from "../assets/img/review-2.svg";
import review3 from "../assets/img/review-3.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { Component } from "react";
import Slider from "react-slick";

import staremptybig from "../assets/img/star-empty-big.svg";
import starfillbig from "../assets/img/star-fill-big.svg";
import testimonials1 from "../assets/img/testimonials-1.svg";

function TimeBilling() {
     const settings = {
          dots: true,
          arrows: false,
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "0",
          slidesToShow: 3,
          speed: 500,
          responsive: [
               {
                    breakpoint: 1025,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
               {
                    breakpoint: 991,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
               {
                    breakpoint: 767,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
               {
                    breakpoint: 480,
                    settings: {
                         slidesToShow: 1,
                         centerMode: false,
                    },
               },
          ],
     };

     return (
          <div>
               <div className="hero-section common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={12} xl={4}>
                                   <div className="__text">
                                        <h2 className="mb-20">Time & Billing</h2>
                                        <p className="mb-60">
                                             Get paid on time with streamlined invoicing and client billing built into your workflow. Track
                                             billable hours and increase the productivity of your team. Create invoices in the same easy to
                                             use system.
                                        </p>
                                        <div className="d-flex">
                                             <a className="btn btn-primary me-3">Request Demo</a>
                                             <a className="btn btn-outline-primary">Start free Trial</a>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={0} xl={2}></Col>
                              <Col lg={12} xl={6}>
                                   <ul className="customer-review-box mt-5 mt-xl-0">
                                        <li className="left15">
                                             <div>
                                                  <img src={quote} />
                                                  <p>
                                                       Our company uses Deupload, and I also use Box personally to store my documents,
                                                       photos, videos, sensitive data etc.{" "}
                                                  </p>
                                                  <div className="text-center">
                                                       <div>
                                                            <b>Rodrigo Duarte</b>
                                                       </div>
                                                       <span>Graphic Designer</span>
                                                       <div className="star d-flex justify-content-center">
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starempty} />
                                                       </div>
                                                       <div className="review-img">
                                                            <img src={review1} />
                                                       </div>
                                                  </div>
                                             </div>
                                        </li>
                                        <li className="mtop-190">
                                             <div>
                                                  <img src={quote} />
                                                  <p>
                                                       Our company uses Deupload, and I also use Box personally to store my documents,
                                                       photos, videos, sensitive data etc.{" "}
                                                  </p>
                                                  <div className="text-center">
                                                       <div>
                                                            <b>Rodrigo Duarte</b>
                                                       </div>
                                                       <span>Graphic Designer</span>
                                                       <div className="star d-flex justify-content-center">
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starempty} />
                                                       </div>
                                                       <div className="review-img">
                                                            <img src={review2} />
                                                       </div>
                                                  </div>
                                             </div>
                                        </li>
                                        <li className="right15">
                                             <div>
                                                  <img src={quote} />
                                                  <p>
                                                       Our company uses Deupload, and I also use Box personally to store my documents,
                                                       photos, videos, sensitive data etc.{" "}
                                                  </p>
                                                  <div className="text-center">
                                                       <div>
                                                            <b>Rodrigo Duarte</b>
                                                       </div>
                                                       <span>Graphic Designer</span>
                                                       <div className="star d-flex justify-content-center">
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starfill} />
                                                            <img src={starempty} />
                                                       </div>
                                                       <div className="review-img">
                                                            <img src={review3} />
                                                       </div>
                                                  </div>
                                             </div>
                                        </li>
                                   </ul>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding-top">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">Grow with Trackmydoc</span>
                                             <h2 className="mb-20">Get paid seamlessly</h2>
                                             <p className="mb-60">
                                                  Thousands of firms prefer Trackmydoc over other software to improve the efficiency of
                                                  their invoice-to-cash cycle and to get paid faster.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Process payments securely</Accordion.Header>
                                                       <Accordion.Body>
                                                            <p className="f-18 mb-1">
                                                                 Put your billing process on autopilot: automatically invoice and charge
                                                                 customers on a set schedule. Get paid faster for your recurring projects
                                                                 (bookkeeping, payroll, accounting) to keep your revenue stream flowing.
                                                            </p>
                                                            <p className="f-18 mb-0">
                                                                 97% of accountants and CPAs either already use recurring invoices or are
                                                                 planning to incorporate them into their practice (based on a Trackmydoc
                                                                 survey of 300+ participants)
                                                            </p>
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Set up recurring invoices and payments</Accordion.Header>
                                                       <Accordion.Body>
                                                            <p className="f-18 mb-1">
                                                                 Put your billing process on autopilot: automatically invoice and charge
                                                                 customers on a set schedule. Get paid faster for your recurring projects
                                                                 (bookkeeping, payroll, accounting) to keep your revenue stream flowing.
                                                            </p>
                                                            <p className="f-18 mb-0">
                                                                 97% of accountants and CPAs either already use recurring invoices or are
                                                                 planning to incorporate them into their practice (based on a Trackmydoc
                                                                 survey of 300+ participants)
                                                            </p>
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Integrated time & billing</Accordion.Header>
                                                       <Accordion.Body>
                                                            <p className="f-18 mb-1">
                                                                 Put your billing process on autopilot: automatically invoice and charge
                                                                 customers on a set schedule. Get paid faster for your recurring projects
                                                                 (bookkeeping, payroll, accounting) to keep your revenue stream flowing.
                                                            </p>
                                                            <p className="f-18 mb-0">
                                                                 97% of accountants and CPAs either already use recurring invoices or are
                                                                 planning to incorporate them into their practice (based on a Trackmydoc
                                                                 survey of 300+ participants)
                                                            </p>
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>Customize invoice design for your brand</Accordion.Header>
                                                       <Accordion.Body>
                                                            <p className="f-18 mb-1">
                                                                 Put your billing process on autopilot: automatically invoice and charge
                                                                 customers on a set schedule. Get paid faster for your recurring projects
                                                                 (bookkeeping, payroll, accounting) to keep your revenue stream flowing.
                                                            </p>
                                                            <p className="f-18 mb-0">
                                                                 97% of accountants and CPAs either already use recurring invoices or are
                                                                 planning to incorporate them into their practice (based on a Trackmydoc
                                                                 survey of 300+ participants)
                                                            </p>
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={Invoice} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <div className="row">
                              <div className="col-md-12">
                                   <div className="slider-container mb-60">
                                        <Slider {...settings}>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="testimonials">
                                                  <div className="d-lg-flex">
                                                       <div className="_left">
                                                            <img className="mb-4" src={quote} />
                                                            <p className="mb-0">
                                                                 Our company uses Deupload, and I also use Box personally to store my
                                                                 documents, photos, videos, sensitive data etc. Deupload is amazing - so
                                                                 much more than just cloud storage. You can watch videos, share photos, scan
                                                                 documents, electronic sign and send documents, secure sensitive data.
                                                            </p>
                                                       </div>
                                                       <div className="_right text-center">
                                                            <img className="mb-3 avt" src={testimonials1} />
                                                            <b>Rodrigo Duarte</b>
                                                            <span>Graphic Designer</span>
                                                            <div className="star d-flex">
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={starfillbig} />
                                                                 <img src={staremptybig} />
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </Slider>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text res-mb-40">
                                             <span className="mb-12 primary-color fw-500 d-block">Pay on the go</span>
                                             <h2 className="mb-20">Provide a Seamless Mobile Experience for Your Clients</h2>
                                             <p className="mb-30">
                                                  Trackmydoc client mobile app provides a full-scale streamlined payment process for you and
                                                  your clients. Push notifications pop up for clients once invoices are ready to be paid.
                                             </p>
                                             <div className="d-flex">
                                                  <a className="me-3">
                                                       <img alt="google play" src={googleplay1} />
                                                  </a>
                                                  <a>
                                                       <img alt="app store" src={appstore1} />
                                                  </a>
                                             </div>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="text-center p-5">
                                             <img src={payonthego} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="res-mb-40">
                                             <h2 className="mb-20">Earn more with an all-in-one approach to your payments</h2>
                                             <p className="mx-1020 mb-0">
                                                  Trackmydoc helps you get paid without delays and having to chase your clients. Increase
                                                  cash flow by leaving repetitive work behind. Stand out from the competition with branded
                                                  invoices. Reduce manual reconciliation while ensuring that you get timely payment for your
                                                  work. Generate custom reports to understand your revenue and firm performance.
                                             </p>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <Row>
                                        <Col xs={12} md={6} lg={12} xl={6}>
                                             <div className="box">
                                                  <div className="d-flex mb-3">
                                                       <div className="icon d-flex me-3">
                                                            <img src={solepractitionerlogo} />
                                                       </div>
                                                       <h5 className="mb-0">Custom automations</h5>
                                                  </div>
                                                  <p className="min-height-48">Robust invoicing based on your firm's needs</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={12} xl={6}>
                                             <div className="box">
                                                  <div className="d-flex mb-3">
                                                       <div className="icon d-flex me-3">
                                                            <img src={filtericon} />
                                                       </div>
                                                       <h5 className="mb-0">Lock docs to invoices</h5>
                                                  </div>
                                                  <p className="min-height-48">Clients can view files only when you got paid</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={12} xl={6}>
                                             <div className="box">
                                                  <div className="d-flex mb-3">
                                                       <div className="icon d-flex me-3">
                                                            <img src={securitytimeicon} />
                                                       </div>
                                                       <h5 className="mb-0">Analyze billing data</h5>
                                                  </div>
                                                  <p className="min-height-48">Analyze data and track your growth</p>
                                             </div>
                                        </Col>
                                        <Col xs={12} md={6} lg={12} xl={6}>
                                             <div className="box">
                                                  <div className="d-flex mb-3">
                                                       <div className="icon d-flex me-3">
                                                            <img src={clipboardtexticon} />
                                                       </div>
                                                       <h5 className="mb-0">Branded invoices</h5>
                                                  </div>
                                                  <p className="min-height-48">Send custom-branded printable PDF invoices</p>
                                             </div>
                                        </Col>
                                   </Row>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default TimeBilling;
