// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import CustomizableTaxOrganizers from "../assets/img/CustomizableTaxOrganizers.svg";

import laptop1 from "../assets/img/laptop-1.svg";
import device1 from "../assets/img/device-1.svg";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";
import templates from "../assets/img/templates.svg";

import filtericon from "../assets/img/filter-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";
import printericon from "../assets/img/printer-icon.svg";
import folderopenicon from "../assets/img/folder-open-icon.svg";
import cardediticon from "../assets/img/card-edit-icon.svg";

function TaxOrganizers() {
     return (
          <div>
               {/*hero section*/}
               <div className="hero-section">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={5}>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-20">Customizable Tax Organizers</h2>
                                        <p className="mb-20">
                                             Scale client onboarding with fully customizable, digital questionnaires. No more clunky PDFs,
                                             no 'generic' organizers or long back and forths to get the information you need. With
                                             Trackmydoc tax organizers, the focus is on the client experience and on saving you time. Your
                                             clients receive personalized, secure forms that they actually enjoy completing.
                                        </p>
                                        <p p className="mb-60">
                                             Use organizers for new client intake, one-off jobs such as annual tax return or recurring jobs
                                             such as monthly payroll or bookkeeping. A modern design and intuitive format help you retain
                                             clients and increase satisfaction scores.
                                        </p>
                                        <div className="d-flex">
                                             <a className="btn btn-primary">Request Demo</a>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={1}></Col>
                              <Col lg={6}>
                                   <div className="hero-picture">
                                        <img src={CustomizableTaxOrganizers} />
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin all-in-one-solution">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="_text mb-60">
                                        <span className="mb-12 fw-500 d-block primary-color">Get all your questions answered</span>
                                        <h2 className="mb-20">Customizable & automated: gather essential client information easily</h2>
                                        <p className="mb-20">
                                             Prepare and send customizable client tax organizers whenever you need to collect information
                                             from your clients. Gather details for personal returns, business returns, new client intake,
                                             bookkeeping and more in a secure manner, without sending emails and documents back and forth.
                                        </p>
                                        <p className="mb-20">
                                             Send organizers to your clients at any time or automatically based on dates or other triggers —
                                             everything is organized and integrated with the rest of your practice management platform.
                                        </p>
                                   </div>
                                   <div className="accordion mb-01 me-3 res-mb-40">
                                        <Accordion defaultActiveKey="1">
                                             <Accordion.Item eventKey="0">
                                                  <Accordion.Header>Integration with CRM</Accordion.Header>
                                                  <Accordion.Body>
                                                       Automatically assign or remove account tags based on client responses in organizers
                                                       to optimize onboarding and similar tasks efficiently. For example, you can utilize
                                                       tag-based conditional automation to the next stage of your pipeline. This helps make
                                                       sure that tasks are given to the right team members and that your clients receive
                                                       personalized messages.
                                                  </Accordion.Body>
                                             </Accordion.Item>
                                             <Accordion.Item eventKey="1">
                                                  <Accordion.Header>
                                                       Assign tasks automatically based on the organizer answers
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                       Automatically assign or remove account tags based on client responses in organizers
                                                       to optimize onboarding and similar tasks efficiently. For example, you can utilize
                                                       tag-based conditional automation to the next stage of your pipeline. This helps make
                                                       sure that tasks are given to the right team members and that your clients receive
                                                       personalized messages.
                                                  </Accordion.Body>
                                             </Accordion.Item>
                                             <Accordion.Item eventKey="2">
                                                  <Accordion.Header>Automate onboarding</Accordion.Header>
                                                  <Accordion.Body>
                                                       Automatically assign or remove account tags based on client responses in organizers
                                                       to optimize onboarding and similar tasks efficiently. For example, you can utilize
                                                       tag-based conditional automation to the next stage of your pipeline. This helps make
                                                       sure that tasks are given to the right team members and that your clients receive
                                                       personalized messages.
                                                  </Accordion.Body>
                                             </Accordion.Item>
                                             <Accordion.Item eventKey="3">
                                                  <Accordion.Header>Save time by applying organizer templates</Accordion.Header>
                                                  <Accordion.Body>
                                                       Automatically assign or remove account tags based on client responses in organizers
                                                       to optimize onboarding and similar tasks efficiently. For example, you can utilize
                                                       tag-based conditional automation to the next stage of your pipeline. This helps make
                                                       sure that tasks are given to the right team members and that your clients receive
                                                       personalized messages.
                                                  </Accordion.Body>
                                             </Accordion.Item>
                                        </Accordion>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-850 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={laptop1} />
                                             <img className="second-img" src={device1} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding">
                    <div className="website-container">
                         <div className="text-center mb-80">
                              <h2 className="text-center mb-20">Create tax organizers customized to your clients</h2>
                              <p className="mx-1020 mb-0">
                                   Make use of various organizer features to gather and sort client data. Send 1 or 10,000 at just the right
                                   time, either manually or automatically, and receive timely notifications when they're completed.
                              </p>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Secure access</h5>
                                        </div>
                                        <p className="min-72">Ensure the protection of client data with secure organizer access</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={templates} />
                                             </div>
                                             <h5 className="mb-0">Templates</h5>
                                        </div>
                                        <p className="min-72">Save time by creating organizer templates</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={printericon} />
                                             </div>
                                             <h5 className="mb-0">Conditions</h5>
                                        </div>
                                        <p className="min-72">Set conditions to skip questions or show previous answers</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={folderopenicon} />
                                             </div>
                                             <h5 className="mb-0">Custom fields</h5>
                                        </div>
                                        <p className="min-72">
                                             Choose the optimal answer format for your clients: radio buttons, checkboxes, etc.
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={filtericon} />
                                             </div>
                                             <h5 className="mb-0">Tags</h5>
                                        </div>
                                        <p className="min-72">Automatically integrate answers from organizers into your CRM using tags</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={clipboardtexticon} />
                                             </div>
                                             <h5 className="mb-0">Audit trail</h5>
                                        </div>
                                        <p className="min-72">
                                             A detailed record of the organizer questions and answers is saved in the audit trail
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Automations</h5>
                                        </div>
                                        <p className="min-72">
                                             Automate when your organizer is sent to clients and set reminders to nudge them
                                        </p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={cardediticon} />
                                             </div>
                                             <h5 className="mb-0">Linking to jobs</h5>
                                        </div>
                                        <p className="min-72">
                                             Run your practice smoothly by linking organizers to jobs to incorporate them into your workflow
                                        </p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default TaxOrganizers;
