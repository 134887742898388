import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import solepractitionerlogo from "../assets/img/sole-practitioner.svg";
import midsizedlargefirmslogo from "../assets/img/mid-sized-large-firms.svg";
import inbox from "../assets/img/inbox.svg";
import seamlesscommunicationinbox from "../assets/img/seamless-communication-inbox.svg";

import projectupdate from "../assets/img/projectupdate.svg";

import enterpriselogo from "../assets/img/enterprise.svg";
import contactgroup from "../assets/img/contact-group.svg";
import attachedfile from "../assets/img/attached-file.svg";

import accountantslogo from "../assets/img/accountants.svg";

import filtericon from "../assets/img/filter-icon.svg";
import securitytimeicon from "../assets/img/security-time-icon.svg";
import clipboardtexticon from "../assets/img/clipboard-text-icon.svg";
import printericon from "../assets/img/printer-icon.svg";
import folderopenicon from "../assets/img/folder-open-icon.svg";
import cardediticon from "../assets/img/card-edit-icon.svg";

function Inbox() {
     return (
          <div>
               <div className="hero-section common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={5}>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-20">Inbox+: personalized notification hub of client activity</h2>
                                        <p className="mb-60">
                                             Your notification center of all emails, messages, tasks and activity relating to any job or
                                             client.
                                        </p>
                                        <div className="d-flex">
                                             <a className="btn btn-primary">Request Demo</a>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={1}></Col>
                              <Col lg={6}>
                                   <div className="hero-picture text-lg-end">
                                        <img src={inbox} />
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin all-in-one-solution">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">Smart in-app notifications</span>
                                             <h2 className="mb-20">Project updates & communication: intra-firm and firm-client</h2>
                                             <p className="mb-60">
                                                  Trackmydoc Inbox+ notification center makes it easier to stay on top of day-to-day
                                                  operations and to keep track of your projects. Inbox+ goes well beyond emails. On one
                                                  easy-to-navigate page, in addition to messages and important notifications, you start and
                                                  finish projects, create tasks, view and download documents, save attachments and more.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Respond directly from the notification</Accordion.Header>
                                                       <Accordion.Body>
                                                            Create a task or follow-up from any email. Quickly deal with issues and ensure
                                                            nothing gets lost as you go about your day.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Turn notifications into actionable tasks</Accordion.Header>
                                                       <Accordion.Body>
                                                            Create a task or follow-up from any email. Quickly deal with issues and ensure
                                                            nothing gets lost as you go about your day.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Filter by activity type</Accordion.Header>
                                                       <Accordion.Body>
                                                            Create a task or follow-up from any email. Quickly deal with issues and ensure
                                                            nothing gets lost as you go about your day.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>Stay focused and on track</Accordion.Header>
                                                       <Accordion.Body>
                                                            Create a task or follow-up from any email. Quickly deal with issues and ensure
                                                            nothing gets lost as you go about your day.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="4">
                                                       <Accordion.Header>Customize notification preferences</Accordion.Header>
                                                       <Accordion.Body>
                                                            Create a task or follow-up from any email. Quickly deal with issues and ensure
                                                            nothing gets lost as you go about your day.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={projectupdate} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding common-margin">
                    <div className="website-container">
                         <div className="text-center mb-80">
                              <h2 className="text-center mb-20">Inbox+ features</h2>
                              <p className="mx-1020 mb-0">
                                   Make use of Inbox+ features to keep yourself updated when your team or clients complete important
                                   actions. Receive timely notifications when a client sends a message, completes a task, fills out an
                                   organizer, or engages in any other activity, all delivered to one convenient place.
                              </p>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Notification preferences</h5>
                                        </div>
                                        <p className="min-height-48">Get notifications by toggling them on and off</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={securitytimeicon} />
                                             </div>
                                             <h5 className="mb-0">Task notifications</h5>
                                        </div>
                                        <p className="min-height-48">Stay up to date with tasks to better track and plan your work</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={printericon} />
                                             </div>
                                             <h5 className="mb-0">Email notifications</h5>
                                        </div>
                                        <p className="min-height-48">Client email alerts ensure that you don't miss any information</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={folderopenicon} />
                                             </div>
                                             <h5 className="mb-0">Creating tasks on the spot</h5>
                                        </div>
                                        <p className="min-height-48">Create a task or follow-up from any email</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={filtericon} />
                                             </div>
                                             <h5 className="mb-0">Filtering</h5>
                                        </div>
                                        <p className="min-height-48">Narrow down your notification list for faster search results</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={clipboardtexticon} />
                                             </div>
                                             <h5 className="mb-0">Bulk actions</h5>
                                        </div>
                                        <p className="min-height-48">Simplify your work with bulk actions for notifications</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Chat updates</h5>
                                        </div>
                                        <p className="min-height-48">Get chat updates, use @mentions for teamwork</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4} xl={3}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={cardediticon} />
                                             </div>
                                             <h5 className="mb-0">Archived tasks</h5>
                                        </div>
                                        <p className="min-height-48">Move items from your To Do list to Archived with one click</p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin all-in-one-solution">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">Seamless communication</span>
                                             <h2 className="mb-20">Minimize busy work and communicate efficiently</h2>
                                             <p className="mb-60">
                                                  Boost productivity and foster team collaboration by allowing your team to work in a single
                                                  mailbox with a shared overview of all email threads. All communication with a client is in
                                                  one place — when an employee takes days off or leaves your firm, you can easily pick up
                                                  where they left off or reassign the work.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Automate email communication</Accordion.Header>
                                                       <Accordion.Body>
                                                            Sync your existing email with Trackmydoc so your team can collaborate within a
                                                            shared inbox. No more Cc or Bcc on emails.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Shared mailbox</Accordion.Header>
                                                       <Accordion.Body>
                                                            Sync your existing email with Trackmydoc so your team can collaborate within a
                                                            shared inbox. No more Cc or Bcc on emails.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Bulk communication, personalized</Accordion.Header>
                                                       <Accordion.Body>
                                                            Sync your existing email with Trackmydoc so your team can collaborate within a
                                                            shared inbox. No more Cc or Bcc on emails.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>Save attachments, organize documents</Accordion.Header>
                                                       <Accordion.Body>
                                                            Sync your existing email with Trackmydoc so your team can collaborate within a
                                                            shared inbox. No more Cc or Bcc on emails.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="bg-color radius-24">
                                        <div className="combine-picture text-center">
                                             <img src={seamlesscommunicationinbox} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="customization common-padding">
                    <div className="website-container">
                         <div className="text-center mb-80">
                              <h2 className="text-center mb-20">Emails integrated into your workflow</h2>
                              <p className="mx-1020 mb-0">
                                   All your firm correspondence is stored and viewed in one central hub connected to your CRM. Trackmydoc
                                   automatically links emails to the respective accounts so you have a full overview of everything that’s
                                   happening with each client.
                              </p>
                         </div>
                         <Row>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={solepractitionerlogo} />
                                             </div>
                                             <h5 className="mb-0">Full email sync</h5>
                                        </div>
                                        <p className="min-height-48">Every email between your firm and clients is in one shared inbox</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={midsizedlargefirmslogo} />
                                             </div>
                                             <h5 className="mb-0">Email templates</h5>
                                        </div>
                                        <p className="min-height-48">Customize and personalize to fit your firm's needs</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={enterpriselogo} />
                                             </div>
                                             <h5 className="mb-0">Send later</h5>
                                        </div>
                                        <p className="min-height-48">Schedule the right email to be sent at the right time</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={accountantslogo} />
                                             </div>
                                             <h5 className="mb-0">Print email threads</h5>
                                        </div>
                                        <p className="min-height-48">Make hard copies of emails whenever needed</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={contactgroup} />
                                             </div>
                                             <h5 className="mb-0">Custom signature</h5>
                                        </div>
                                        <p className="min-height-48">Add your brand to every email you send</p>
                                   </div>
                              </Col>
                              <Col xs={12} md={6} lg={4}>
                                   <div className="box">
                                        <div className="d-flex mb-3">
                                             <div className="icon d-flex me-3">
                                                  <img src={attachedfile} />
                                             </div>
                                             <h5 className="mb-0">Attach files</h5>
                                        </div>
                                        <p className="min-height-48">Add images, documents from your Trackmydoc or computer</p>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default Inbox;
