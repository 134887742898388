import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Accordion from "react-bootstrap/Accordion";

import SecureMessagesicon from "../assets/img/SecureMessages.svg";
import clienttask from "../assets/img/clienttask.svg";

import messagingcapabilities from "../assets/img/messaging-capabilities.svg";

import customizableuser from "../assets/img/customizable-user.svg";

function SecureMessages() {
     return (
          <div>
               <div className="hero-section common-padding">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg={5}>
                                   <div className="__text res-mb-40">
                                        <h2 className="mb-20">Secure Messages & Client Tasks</h2>
                                        <p className="mb-60">
                                             Trackmydoc integrated messaging guarantees a secure environment to connect with clients
                                             directly within your workflow. Chat in real time, upload or scan documents, easily request
                                             information and automatically remind them to complete your tasks.
                                        </p>
                                        <div className="d-flex">
                                             <a className="btn btn-primary">Request Demo</a>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg={1}></Col>
                              <Col lg={6}>
                                   <div className="hero-picture">
                                        <img src={SecureMessagesicon} />
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">
                                                  Let clients communicate with you on the go
                                             </span>
                                             <h2 className="mb-20">Messaging capabilities integrated with client portal</h2>
                                             <p className="mb-60">
                                                  Offer your clients an easy and secure way to communicate and share documents through their
                                                  client portal or mobile devices.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>A client portal with built-in chats</Accordion.Header>
                                                       <Accordion.Body>
                                                            In addition to the web-based client portal, offer your clients a way to
                                                            communicate with your team while they're out and about with our native iOS and
                                                            Android client mobile apps. Both firms and clients have native apps to
                                                            communicate on the go.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Send instant messages from mobile devices</Accordion.Header>
                                                       <Accordion.Body>
                                                            In addition to the web-based client portal, offer your clients a way to
                                                            communicate with your team while they're out and about with our native iOS and
                                                            Android client mobile apps. Both firms and clients have native apps to
                                                            communicate on the go.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Attach links, images and videos</Accordion.Header>
                                                       <Accordion.Body>
                                                            In addition to the web-based client portal, offer your clients a way to
                                                            communicate with your team while they're out and about with our native iOS and
                                                            Android client mobile apps. Both firms and clients have native apps to
                                                            communicate on the go.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={messagingcapabilities} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>

               <div className="common-margin common-padding">
                    <div className="website-container">
                         <div className="customizable-user">
                              <img src={customizableuser} />
                              <h4 className="mb-60">
                                   Our clients love the portal. And we love the messages feature where you can send direct messages to the
                                   client.
                              </h4>
                              <div className="mb-0 sub-div">
                                   <h5 className="me-4 mb-0">Kristin Gravitt</h5>Partner at Fox Gravitt, PLLC
                              </div>
                         </div>
                    </div>
               </div>

               <div className="common-margin">
                    <div className="website-container">
                         <Row className="align-items-center">
                              <Col lg="6">
                                   <div className="col-xl-pdright">
                                        <div className="_text mb-60">
                                             <span className="mb-12 fw-500 d-block primary-color">
                                                  Fast way to communicate with clients
                                             </span>
                                             <h2 className="mb-20">Client tasks that help move projects along</h2>
                                             <p className="mb-60">
                                                  Streamline collaboration and keep projects moving forward with easy, secure chat
                                                  workflows.
                                             </p>
                                        </div>
                                        <div className="accordion mb-01 me-3 res-mb-40">
                                             <Accordion defaultActiveKey="1">
                                                  <Accordion.Item eventKey="0">
                                                       <Accordion.Header>Client tasks</Accordion.Header>
                                                       <Accordion.Body>
                                                            No more illegible documents: your clients can easily scan and share
                                                            high-resolution collated PDFs directly from their mobile phones.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="1">
                                                       <Accordion.Header>Integrated with mobile scanner</Accordion.Header>
                                                       <Accordion.Body>
                                                            No more illegible documents: your clients can easily scan and share
                                                            high-resolution collated PDFs directly from their mobile phones.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="2">
                                                       <Accordion.Header>Preset templates and shortcodes</Accordion.Header>
                                                       <Accordion.Body>
                                                            No more illegible documents: your clients can easily scan and share
                                                            high-resolution collated PDFs directly from their mobile phones.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                                  <Accordion.Item eventKey="3">
                                                       <Accordion.Header>Incorporated into your workflow</Accordion.Header>
                                                       <Accordion.Body>
                                                            No more illegible documents: your clients can easily scan and share
                                                            high-resolution collated PDFs directly from their mobile phones.
                                                       </Accordion.Body>
                                                  </Accordion.Item>
                                             </Accordion>
                                        </div>
                                   </div>
                              </Col>
                              <Col lg="6">
                                   <div className="min-height-750 bg-color radius-24">
                                        <div className="combine-picture text-center p-5">
                                             <img src={clienttask} />
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </div>
               </div>
          </div>
     );
}
export default SecureMessages;
